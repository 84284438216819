var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.$route.name == 'Index' ||
      _vm.$route.name == 'About' ||
      _vm.$route.name == 'Faq' ||
      _vm.$route.name == 'Contact' ||
      _vm.$route.name == 'Privacy' ||
      _vm.$route.name == 'Terms'
    )?_c('div',{staticClass:"flex flex-col h-screen"},[_c('main',{staticClass:"flex-1 overflow-y-auto w-full",attrs:{"id":"mainEl"}},[_c('router-view',{key:_vm.$route.fullPath})],1)]):_c('div',{staticClass:"flex flex-col h-screen screen-bg",attrs:{"id":"backgroundImgId"}},[_c('main',{staticClass:"flex-1 overflow-y-auto w-full",attrs:{"id":"mainEl"}},[_c('div',{staticClass:"flex ui-container mx-auto py-6 px-4 min-h-screen flex-1 items-center justify-center"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view')],1)],1)]),(_vm.isRootLoading)?[_c('div',{staticClass:"absolute flex items-center justify-center mx-auto bg-white opacity-95 w-screen h-screen left-0 top-0 z-40"},[_c('svg',{staticClass:"relative animate-spin h-14 w-14 text-dark",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24"}},[_c('circle',{staticClass:"opacity-25",attrs:{"cx":"12","cy":"12","r":"10","stroke":"currentColor","stroke-width":"4"}}),_c('path',{staticClass:"opacity-75",attrs:{"fill":"currentColor","d":"M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"}})])])]:_vm._e(),_c('span',{staticClass:"version-number mb-0 text-xs text-muted text-opacity-20 text-center w-100"},[_vm._v(" "+_vm._s(this.version)+" ")])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }
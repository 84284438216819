import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/info",
  //   name: "UserInfo",
  //   component: () => import( /* webpackChunkName: "user" */ "../views/UserInfo.vue"),
  // },
  {
    path: "/generate/:campaignName/:videoName",
    name: "GenerateVideo",
    component: () =>
      import(/* webpackChunkName: "generate" */ "../views/GenerateVideo.vue"),
  },
  {
    path: "/result/:campaignName/:videoName",
    name: "ResultVideo",
    component: () =>
      import(
        /* webpackChunkName: "result" */ "../views-giveproudly/ResultVideo.vue"
      ),
  },
  {
    path: "/preview/:campaignName/:videoName",
    name: "PreviewVideo",
    component: () =>
      import(
        /* webpackChunkName: "preview" */ "../views-giveproudly/PreviewVideo.vue"
      ),
  },
  {
    path: "/preview/:campaignName/demos/:videoName",
    name: "PreviewVideo",
    meta: {
      isDemo: true,
    },
    component: () =>
      import(/* webpackChunkName: "preview" */ "../views-giveproudly/PreviewVideo.vue"),
  },
  {
    path: "/download/:id",
    name: "DownloadVideo",
    component: () =>
      import(/* webpackChunkName: "download" */ "../views/DownloadVideo.vue"),
  },
  {
    path: "/",
    name: "Index",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views-giveproudly/Index.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views-giveproudly/About.vue"),
  },
  {
    path: "/faq",
    name: "Faq",
    component: () =>
      import(/* webpackChunkName: "faq" */ "@/views-giveproudly/Faq.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "@/views-giveproudly/Contact.vue"
      ),
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () =>
      import(
        /* webpackChunkName: "privacy" */ "@/views-giveproudly/Privacy.vue"
      ),
  },
  {
    path: "/terms",
    name: "Terms",
    component: () =>
      import(/* webpackChunkName: "terms" */ "@/views-giveproudly/Terms.vue"),
  },
  // {
  //   path: "/not-found",
  //   name:"NotFound",
  //   meta:{
  //     msg : "Sorry we couldn't find the requested campaign."
  //   },
  //   component: () =>
  //     import( /* webpackChunkName: "contact" */ "@/views/Message.vue"),
  // },
  {
    path: "NotFound",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Error404.vue"),
  },
  {
    path: "/:nameId",
    name: "UserInfo",
    component: () =>
      import(
        /* webpackChunkName: "donor" */ "../views-giveproudly/UserInfo.vue"
      ),
  },
  {
    path: "*",
    name: "404",
    component: () =>
      import(
        /* webpackChunkName: "notfound" */ "../views-giveproudly/Error404.vue"
      ),
  },
  // {
  //   path: "/cities/:country/:city",
  //   name: "CityProfileOrganiser",
  //   meta: {
  //     isCity: false,
  //     hasFooter: true,
  //   },
  //   component: CityProfileOrganiser,
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash,
      };
    } else {
      // return document.getElementById("mainEl").scrollIntoView();
      return document.getElementById("mainEl").scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      // return { x: 0, y: 0 };
    }
  },
});

// router.beforeEach((to, from, next) => {
//   let isAuth = localStorage.getItem("isAuth") ? localStorage.getItem("isAuth") : false;

//   if (to.name == "UserInfo" && !isAuth) {
//     next({
//       name: "Home"
//     });
//   }
//   if (to.name == "GenerateVideo" && !isAuth) {
//     next({
//       name: "Home"
//     });
//   }
//   next();
// });
export default router;

<template>
  <div>
    <div
      v-if="
        $route.name == 'Index' ||
        $route.name == 'About' ||
        $route.name == 'Faq' ||
        $route.name == 'Contact' ||
        $route.name == 'Privacy' ||
        $route.name == 'Terms'
      "
      class="flex flex-col h-screen"
    >
      <main id="mainEl" class="flex-1 overflow-y-auto w-full">
        <router-view :key="$route.fullPath" />
      </main>
    </div>
    <div v-else class="flex flex-col h-screen screen-bg" id="backgroundImgId">
      <main id="mainEl" class="flex-1 overflow-y-auto w-full">
        <div
          class="flex ui-container mx-auto py-6 px-4 min-h-screen flex-1 items-center justify-center"
        >
          <transition name="fade" mode="out-in">
            <router-view />
          </transition>
        </div>
      </main>
      <!-- spinner loader (only when load state is true) -->
      <template v-if="isRootLoading">
        <div
          class="absolute flex items-center justify-center mx-auto bg-white opacity-95 w-screen h-screen left-0 top-0 z-40"
        >
          <!-- heroicon - progress loader icon -->
          <svg
            class="relative animate-spin h-14 w-14 text-dark"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      </template>
      <!-- version number -->
      <!-- <span
        class="
          absolute
          left-1/2
          transform
          -translate-x-1/2
          bottom-0
          text-xs text-black text-opacity-20
        "
        >V1.0.0.13</span
      > -->
      <span
        class="version-number mb-0 text-xs text-muted text-opacity-20 text-center w-100"
      >
        {{ this.version }}
      </span>
    </div>
  </div>
</template>

<script>
// store
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      isRootLoading: "getIsRootLoading",
    }),
  },
  watch: {
    $route(to) {
      // only run this on these specific routes
      console.log("router: ", to);
    },
  },
  methods: {
    // backHomeFn(e) {
    //   e.preventDefault();
    //   this.$router.replace("/");
    // },
  },
};
</script>
<style>
.screen-bg {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  /* url(https://images.unsplash.com/photo-1592781567839-29b15f27d70d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyOTM1NDI5Mg&ixlib=rb-1.2.1&q=80w=1080); */
  background-size: cover;
}

.h-screen {
  position: relative;
}

.screen-bg1 {
  background-image: url("https://images.unsplash.com/photo-1561084711-b2e525f5a4c0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80");
  background-size: cover;
}

/* global style reset */
input,
button {
  /* moz fix */
  outline: none !important;
}

.version-number {
  font-size: 0.7rem;
  line-height: 20px;
  display: block;
  /* margin-left: 250px;
  margin-right: auto; */
}

/* md and up */
@media (min-width: 48rem) {
}

/* lg and up */
@media (min-width: 78rem) {
  .ui-container {
    max-width: 76rem;
  }
}

/* animations */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

@media (max-width: 991.98px) {
  .version-number {
    text-align: center;
    font-size: 0.7rem;
    line-height: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>

import Vue from "vue";
import Vuex from "vuex";
import users from "./modules/users";

// Load Vuex
Vue.use(Vuex);

// Create Store
export default new Vuex.Store({
  modules: {
    users,
  },
  state: {
    isRootLoading: false,
  },
  mutations: {
    SET_IS_ROOT_LOADING(state, payload) {
      state.isRootLoading = payload;
    },
  },
  actions: {
    setIsRootLoading({ commit }, payload) {
      commit("SET_IS_ROOT_LOADING", payload);
    },
  },
  getters: {
    getIsRootLoading: (state) => state.isRootLoading,
  },
});

import Vue from "vue";
import VueMeta from "vue-meta";
import App from "./App.vue";
// import router from "./router";
import store from "./store";
import "./plugins/fontawesome-vue";
import "./plugins/bootstrap-vue";
import "./plugins/recaptcha-vue";
import "./plugins/composition-api";
import "./plugins/editor-vue";

import "./assets/styles/index.css";

import personalPacRouter from "./router/personal-pac";
import giveProudlyRouter from "./router/give-proudly";

const router =
  process.env.VUE_APP_NAME === "give-proudly"
    ? giveProudlyRouter
    : personalPacRouter;

Vue.config.productionTip = false;

Vue.use(VueMeta);
Vue.prototype.version = window.version;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

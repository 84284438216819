export default {
    namespaced: true,
    state: {
      userDetails: null,
    },
    mutations: {
      SET_USER_DETAILS(state, payload) {
        state.userDetails = payload;
      },
    },
    actions: {
      setUserDetails({ commit }, payload) {
        commit("SET_USER_DETAILS", payload);
      },
    },
    getters: {
      getUserDetails: (state) => state.userDetails,
    },
  };
  